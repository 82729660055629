import React from "react";
import "./inputField.css";

const InputField = ({ id, labelText, ...props }) => {
  return (
    <div className="inputField-main">
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >
        {labelText}
      </label>
      <input type="text" id={id} className="input input-bordered" {...props} />
    </div>
  );
};
export default InputField;

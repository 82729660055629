import React from 'react'

const SkillCard = (props) => {
  return (
    <div className='skill-card-container'>
        <img src={props.logo} alt={props.title} className="skill-logo" />
        <p className="skill-title">{props.title}</p>
    </div>
  )
}

export default SkillCard
import React from "react";
import "./portfolio.css";

const Portfolio = () => {
  return (
    <>
      <div className="portfolio-container my-container">
        <h1 className="center">this page is not available</h1>
      </div>
    </>
  );
};

export default Portfolio;

import React from "react";
import "./social.css";

const Social = ({ page }) => {
  return (
    <div
      className={
        page === "home"
          ? "social-container home-social"
          : page === "contact"
          ? "social-container contact-social"
          : "social-container"
      }
    >
      <div className="ligne1"></div>
      <div className="social">
        <a className="social-icon " href="https://www.instagram.com/hakim_skr">
          <i className="fa-brands fa-instagram"></i>
        </a>
        <a className="social-icon " href="https://github.com/hakim-skr">
          <i className="fa-brands fa-github"></i>
        </a>
        <a className="social-icon " href="https://www.linkedin.com/in/hakim-soukrati-0b7082244">
          <i className="fa-brands fa-linkedin"></i>
        </a>
        <a className="social-icon " href="https://discord.com/users/hakim_skr">
          <i className="fa-brands fa-discord"></i>
        </a>
        <a
          className="social-icon "
          href="https://api.whatsapp.com/send?phone=0625070500"
        >
          <i className="fa-brands fa-whatsapp"></i>
        </a>
      </div>
      <div className="ligne2"></div>
    </div>
  );
};

export default Social;

import React from "react";
import "./skills.css";
import java from "../../../Assets/Dev-icons/java-original.svg";
import nodejs from "../../../Assets/Dev-icons/nodejs-original.svg";
import express from "../../../Assets/Dev-icons/express-original.svg";
import kotlin from "../../../Assets/Dev-icons/kotlin-original.svg";
import androidstudio from "../../../Assets/Dev-icons/androidstudio-original.svg";
import reactjs from "../../../Assets/Dev-icons/react-original.svg";
import javascript from "../../../Assets/Dev-icons/javascript-original.svg";
import css from "../../../Assets/Dev-icons/css3-original.svg";
import html from "../../../Assets/Dev-icons/html5-original.svg";
import dart from "../../../Assets/Dev-icons/dart-original.svg";
import bootstrap from "../../../Assets/Dev-icons/bootstrap-original.svg";
import flutter from "../../../Assets/Dev-icons/flutter-original.svg";
import git from "../../../Assets/Dev-icons/git-original.svg";
import github from "../../../Assets/Dev-icons/github-original.svg";
import laravel from "../../../Assets/Dev-icons/laravel-plain.svg";
import php from "../../../Assets/Dev-icons/php-original.svg";
import python from "../../../Assets/Dev-icons/python-original.svg";
import redux from "../../../Assets/Dev-icons/redux-original.svg";
import mySql from "../../../Assets/Dev-icons/mysql-original.svg";
import sass from "../../../Assets/Dev-icons/sass-original.svg";
import sqLite from "../../../Assets/Dev-icons/sqlite-original.svg";
import swift from "../../../Assets/Dev-icons/swift-original.svg";
import tailwind from "../../../Assets/Dev-icons/tailwindcss-plain.svg";
import typescript from "../../../Assets/Dev-icons/typescript-original.svg";
import npm from "../../../Assets/Dev-icons/npm-original-wordmark.svg";
import yarn from "../../../Assets/Dev-icons/yarn-original.svg";
import SkillCard from "./SkillCard";
import { motion } from "framer-motion";
const Skills = () => {
  const cardsData = [
    { title: "Flutter", src: flutter },
    { title: "Java", src: java },
    { title: "Node js", src: nodejs },
    { title: "express", src: express },
    { title: "Javascript", src: javascript },
    { title: "React js", src: reactjs },
    { title: "Kotlin", src: kotlin },
    { title: "Android studio", src: androidstudio },
    { title: "React native", src: reactjs },
    { title: "MySql", src: mySql },
    // { title: "Typescript", src: typescript },
    { title: "Dart", src: dart },
    { title: "Git", src: git },
    { title: "Github", src: github },
    { title: "PHP", src: php },
    { title: "SQLite", src: sqLite },
    // { title: "Python", src: python },
    // { title: "Swift", src: swift },
    { title: "Npm", src: npm },
    // { title: "Yarn", src: yarn },
    { title: "Css", src: css },
    { title: "Html", src: html },
  ];
  return (
    <motion.div
      className="skills-container my-container"
      // transition={{ duration: 1 }}
      // initial={{ opacity: 0.2 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0.2 }}
    >
      <p className="skills-title-2">
        Technology I've worked & <span className="chakra-text">dabbled</span>{" "}
        with:
      </p>
      <div className="skills-content grid">
        {/* .sort(() => Math.random() - 0.5) */}
        {cardsData.map((value) => (
          <SkillCard logo={value.src} title={value.title} />
        ))}
      </div>
    </motion.div>
  );
};

export default Skills;

import React from "react";
import "./home.css";
import Social from "../../social/Social";
import Data from "./Data";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <motion.div
      className="home-container my-container"
      // transition={{ duration: 1 }}
      // initial={{ opacity: 0.2 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0.2 }}
    >
      <Social page={"home"} />
      <div className="home-content grid">
        <div className="home-img"></div>
        <Data />
      </div>
    </motion.div>
  );
};

export default Home;
